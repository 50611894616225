var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import { auth } from '../app-enter.js';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, getAuth, onAuthStateChanged, } from 'firebase/auth';
import { go } from '../router/router-base.js';
import { routes } from '../router/routes.js';
import { getUserData } from './firebase-utils.js';
import { collection, getDocs } from 'firebase/firestore';
import { database } from '../app-enter.js';
import { ref, get } from 'firebase/database';
import { listUsers } from 'firebase/auth';
export const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
};
export const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
};
export function setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
}
export function updateCurrentUser() {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield getCurrentUser();
        const additionalData = yield getUserData(user.user.uid);
        const combinedData = Object.assign(Object.assign({}, user), { additionalData: additionalData });
        setCurrentUser(combinedData);
    });
}
export function getCurrentUser() {
    const userStr = localStorage.getItem('currentUser');
    return userStr ? JSON.parse(userStr) : null;
}
export function clearCurrentUser() {
    localStorage.removeItem('currentUser');
    go(routes.LOGIN.path);
}
export function getAllUsers() {
    return __awaiter(this, void 0, void 0, function* () { });
}
