var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
import { LitElement, css, html } from 'lit';
import BackArrowElement from './back-arrow.js';
import { go } from '../router/router-base.js';
import routes from '../router/routes.js';
import getRoutes from '../router/routes.js';
import AppEvents from '../shared/app-events.js';
import { logoutIcon, homeIcon } from '../assets/icons.js';
import { getCurrentUser } from '../utility/auth-service.js';
class AppHeaderElement extends LitElement {
    constructor() {
        super();
        this.user = null;
        this.profilePic = '';
        this.currentLocation = window.location.pathname;
        this.hideButton = this.currentLocation === '/dashboard';
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            this.user = yield getCurrentUser();
            this.profilePic = ((_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.additionalData) === null || _b === void 0 ? void 0 : _b.profilePic) || '';
            window.addEventListener('update-header', () => __awaiter(this, void 0, void 0, function* () {
                var _c, _d;
                console.log('got it');
                this.user = yield getCurrentUser();
                this.profilePic = ((_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.additionalData) === null || _d === void 0 ? void 0 : _d.profilePic) || '';
                this.requestUpdate();
            }));
            this.requestUpdate();
        });
    }
    render() {
        return html `
      <div class="header">
        <span @click=${() => go(routes.DASHBOARD.path)}
          ><svg width="252" height="36" viewBox="0 0 252 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.558 29.0249C15.537 29.0249 16.2459 28.738 16.6847 28.1641C17.1573 27.5565 17.5118 26.8307 17.7481 25.9868H24.1282C23.9932 27.2358 23.6725 28.4173 23.1661 29.5313C22.6598 30.6115 21.9846 31.5567 21.1407 32.3669C20.3305 33.1771 19.3516 33.8185 18.2038 34.2911C17.0898 34.7637 15.8914 35 14.6086 35H10.2539C8.90364 35 7.62086 34.7468 6.40559 34.2405C5.19033 33.7003 4.12697 32.9746 3.21552 32.0631C2.30407 31.1517 1.57828 30.0883 1.03817 28.873C0.531805 27.6578 0.278625 26.3581 0.278625 24.9741V10.5934C0.278625 9.20935 0.531805 7.90969 1.03817 6.69442C1.57828 5.4454 2.30407 4.36516 3.21552 3.45371C4.12697 2.54226 5.19033 1.83335 6.40559 1.32699C7.62086 0.786872 8.90364 0.516813 10.2539 0.516813H14.6086C15.9252 0.516813 17.1404 0.753114 18.2544 1.22572C19.3684 1.69832 20.3305 2.35659 21.1407 3.20053C21.9846 4.0107 22.6598 4.97279 23.1661 6.08678C23.6725 7.20078 23.9932 8.39917 24.1282 9.68195H17.7481C17.5793 8.80426 17.2586 8.06159 16.786 7.45396C16.3134 6.84633 15.5707 6.54251 14.558 6.54251H10.3046C9.12306 6.54251 8.17786 6.96448 7.46895 7.80841C6.7938 8.65235 6.45623 9.68195 6.45623 10.8972V24.6196C6.45623 25.8349 6.7938 26.8813 7.46895 27.759C8.17786 28.603 9.12306 29.0249 10.3046 29.0249H14.558Z"
              fill="#BD3339"
            />
            <path
              d="M41.9422 0.516813C43.2925 0.516813 44.5753 0.786872 45.7906 1.32699C47.0059 1.83335 48.0692 2.54226 48.9807 3.45371C49.8921 4.36516 50.601 5.4454 51.1074 6.69442C51.6475 7.90969 51.9176 9.20935 51.9176 10.5934V24.9741C51.9176 26.3581 51.6475 27.6578 51.1074 28.873C50.601 30.0883 49.8921 31.1517 48.9807 32.0631C48.0692 32.9746 47.0059 33.7003 45.7906 34.2405C44.5753 34.7468 43.2925 35 41.9422 35H37.9926C36.6086 35 35.3089 34.7468 34.0937 34.2405C32.8784 33.7003 31.815 32.9746 30.9036 32.0631C30.0259 31.1517 29.317 30.0883 28.7769 28.873C28.2367 27.6578 27.9667 26.3581 27.9667 24.9741V10.5934C27.9667 9.20935 28.2367 7.90969 28.7769 6.69442C29.317 5.4454 30.0259 4.36516 30.9036 3.45371C31.815 2.54226 32.8784 1.83335 34.0937 1.32699C35.3089 0.786872 36.6086 0.516813 37.9926 0.516813H41.9422ZM45.74 10.8972C45.74 9.68195 45.3855 8.65235 44.6766 7.80841C44.0014 6.96448 43.0731 6.54251 41.8916 6.54251H38.0433C36.8618 6.54251 35.9166 6.96448 35.2076 7.80841C34.5325 8.65235 34.1949 9.68195 34.1949 10.8972V24.6196C34.1949 25.8349 34.5325 26.8813 35.2076 27.759C35.9166 28.603 36.8618 29.0249 38.0433 29.0249H41.8916C43.0731 29.0249 44.0014 28.603 44.6766 27.759C45.3855 26.8813 45.74 25.8349 45.74 24.6196V10.8972Z"
              fill="#E19E2B"
            />
            <path d="M62.023 29.1768H80.7077V35H55.9467V0.516813H62.023V29.1768Z" fill="#429754" />
            <path
              d="M98.2156 0.516813C99.5659 0.516813 100.849 0.786872 102.064 1.32699C103.279 1.83335 104.343 2.54226 105.254 3.45371C106.165 4.36516 106.874 5.4454 107.381 6.69442C107.921 7.90969 108.191 9.20935 108.191 10.5934V24.9741C108.191 26.3581 107.921 27.6578 107.381 28.873C106.874 30.0883 106.165 31.1517 105.254 32.0631C104.343 32.9746 103.279 33.7003 102.064 34.2405C100.849 34.7468 99.5659 35 98.2156 35H94.266C92.8819 35 91.5822 34.7468 90.367 34.2405C89.1517 33.7003 88.0883 32.9746 87.1769 32.0631C86.2992 31.1517 85.5903 30.0883 85.0502 28.873C84.5101 27.6578 84.24 26.3581 84.24 24.9741V10.5934C84.24 9.20935 84.5101 7.90969 85.0502 6.69442C85.5903 5.4454 86.2992 4.36516 87.1769 3.45371C88.0883 2.54226 89.1517 1.83335 90.367 1.32699C91.5822 0.786872 92.8819 0.516813 94.266 0.516813H98.2156ZM102.013 10.8972C102.013 9.68195 101.659 8.65235 100.95 7.80841C100.275 6.96448 99.3464 6.54251 98.1649 6.54251H94.3166C93.1351 6.54251 92.1899 6.96448 91.481 7.80841C90.8058 8.65235 90.4682 9.68195 90.4682 10.8972V24.6196C90.4682 25.8349 90.8058 26.8813 91.481 27.759C92.1899 28.603 93.1351 29.0249 94.3166 29.0249H98.1649C99.3464 29.0249 100.275 28.603 100.95 27.759C101.659 26.8813 102.013 25.8349 102.013 24.6196V10.8972Z"
              fill="#5574B8"
            />
            <path
              d="M119.461 21.0751V35H113.03V0.516813H127.816C129.132 0.516813 130.365 0.769994 131.512 1.27636C132.66 1.74896 133.639 2.40723 134.449 3.25116C135.293 4.0951 135.951 5.09094 136.424 6.23869C136.93 7.38644 137.184 8.60171 137.184 9.88449V11.4036C137.184 12.6864 136.93 13.9016 136.424 15.0494C135.951 16.1634 135.293 17.1592 134.449 18.0369C133.639 18.8808 132.66 19.5391 131.512 20.0117C130.365 20.4843 129.132 20.7206 127.816 20.7206H126.094L137.791 35H130.449L119.461 21.0751ZM119.461 6.54251V14.6949H126.803C128.255 14.6949 129.318 14.3405 129.993 13.6316C130.668 12.9227 131.006 11.9268 131.006 10.644C131.006 9.42877 130.668 8.4498 129.993 7.70714C129.318 6.93072 128.255 6.54251 126.803 6.54251H119.461Z"
              fill="#9E4597"
            />
            <path
              d="M155.889 29.0249C156.868 29.0249 157.661 28.738 158.269 28.1641C158.876 27.5565 159.282 26.8307 159.484 25.9868V20.2649H153.762V14.8468H165.307V35H159.484V33.886C158.809 34.2573 158.066 34.5443 157.256 34.7468C156.48 34.9156 155.686 35 154.876 35H151.382C150.032 35 148.749 34.7468 147.534 34.2405C146.319 33.7003 145.255 32.9746 144.344 32.0631C143.432 31.1517 142.707 30.0883 142.167 28.873C141.66 27.6578 141.407 26.3581 141.407 24.9741V10.5934C141.407 9.20935 141.66 7.90969 142.167 6.69442C142.707 5.4454 143.432 4.36516 144.344 3.45371C145.255 2.54226 146.319 1.83335 147.534 1.32699C148.749 0.786872 150.032 0.516813 151.382 0.516813H155.737C157.054 0.516813 158.269 0.753114 159.383 1.22572C160.497 1.69832 161.459 2.35659 162.269 3.20053C163.113 4.0107 163.788 4.97279 164.294 6.08678C164.801 7.20078 165.122 8.39917 165.257 9.68195H159.028C158.792 8.80426 158.421 8.06159 157.914 7.45396C157.442 6.84633 156.699 6.54251 155.686 6.54251H151.433C150.859 6.54251 150.319 6.66066 149.813 6.89696C149.34 7.09951 148.935 7.40332 148.597 7.80841C148.26 8.2135 147.99 8.68611 147.787 9.22622C147.618 9.73259 147.534 10.2896 147.534 10.8972V24.6196C147.534 25.2272 147.618 25.8011 147.787 26.3412C147.99 26.8476 148.26 27.3033 148.597 27.7084C148.935 28.1135 149.34 28.4342 149.813 28.6705C150.319 28.9068 150.859 29.0249 151.433 29.0249H155.889ZM185.439 29.7338H176.172L174.906 35H168.779L177.641 0.516813H184.223L192.73 35H186.654L185.439 29.7338ZM177.387 24.6196H184.274L181.084 10.5428H180.729L177.387 24.6196ZM209.854 25.4298H210.209C211.12 21.2776 212.032 17.1423 212.943 13.0239C213.888 8.87177 214.817 4.71961 215.728 0.567451H222.969V35H218.007V12.6188H217.703C216.893 16.3659 216.049 20.113 215.171 23.8601C214.293 27.5734 213.466 31.2867 212.69 35L207.171 35.0506L202.461 12.6695H202.107V35H197.094V0.567451H204.335L209.854 25.4298ZM234.285 29.1768H251.45V35H227.753V0.516813H251.45V6.33996H234.285V14.8468H251.45V20.6193H234.285V29.1768Z"
              fill="#3E3E3E"
            />
          </svg>
        </span>
        ${this.profilePic
            ? html ` <div class="profile-pic" style="background-image: url('${this.profilePic}')"></div> `
            : ''}
      </div>
    `;
    }
}
AppHeaderElement.properties = {
    user: { type: Object },
    profilePic: { type: String },
};
AppHeaderElement.styles = css `
    svg {
      scale: 4;
      margin-left: 40px;
    }
    .header {
      display: flex;
      justify-content: left;
      align-items: center;
      height: 64px;
      width: 100vw;
      padding-left: 16px;
      margin-bottom: 100px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      background-color: #f2efe5;
      font-family: 'Arial';
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      gap: 16px;
      margin: 0;
    }
    .rainbow-container {
      position: absolute;
      right: 64px; // Moved to the left to make room for the profile pic
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: left;
      gap: 0;
    }
    .header > h1 {
      position: absolute;
      left: 16px;
      margin: 0;
      font-family: 'Arial';
      color: #515151;
      font-size: 24px;
    }
    .rainbow-child {
      height: 64px;
      width: 16px;
    }
    svg {
      cursor: pointer;
      height: 32px;
      width: 32px;
    }
    .hide {
      display: none;
    }
    .profile-pic {
      position: absolute;
      right: 32px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ccc;
      background-size: cover;
      background-position: center;
      border: 4px solid grey;
      cursor: pointer;
    }
  `;
customElements.define('app-header', AppHeaderElement);
export default AppHeaderElement;
